import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const RazorpayPayment = ({ password, email, phone }) => {
    const navigate = useNavigate();


    const packageId = 2;

console.log("Razorypay key id : ", process.env.REACT_APP_RAZORPAY_KEY);

    const handlePayment = (e) => {
        axios.post(process.env.REACT_APP_API_RAZORPAY_CHECKOUT, { packageId, email: email, password: password, phone: phone }) // Send payload directly
            .then(res => {
                console.log("Order created successfully:", res.data.order.id);

                const options = {
                    "key": process.env.REACT_APP_RAZORPAY_KEY, // ENV vairable for safety
                    "amount": res.data.options.amount, //  backend
                    "currency": res.data.options.currency,//backend
                    "name": res.data.options.packageName, //backend
                    "description": "Test Transaction",
                    "image": "https://example.com/your_logo",
                    "order_id": res.data.order.id, // backend
                    "handler": function (response) {
                        navigate('/dashboard')
                    },
                    "prefill": {
                        "name": email, //frontend
                        "email": email, //frontend
                        "contact": phone, //frontend
                    },
                    "notes": {
                        "address": "Razorpay Corporate Office",
                    },
                    "theme": {
                        "color": "#3399cc",
                    }
                };

                const rzp1 = new window.Razorpay(options);

                rzp1.on('payment.failed', function (response) {
                    alert("Payment failed. Please try again.");
                    console.error("Payment failed details:", response);
                });

                rzp1.open();
                e.preventDefault();
            })
            .catch(err => {
                console.error("Error creating order:", err.response?.data || err.message);
                alert("Error initiating payment. Please try again later.");
            });
    };


    return (
        <div>
            <button
                onClick={handlePayment}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            >
                Pay ₹51,215
            </button>
        </div>
    )
}

export default RazorpayPayment
