import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const BlogDetail = () => {
    const [blog, setBlog] = useState(null);
    const [blogData, setBlogData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_SINGLE_BLOG, { params: { id: id } })
            .then(res => {
                console.log(res.data.data);
                setBlog(res.data.data);

                if (res.data.data && res.data.data.blog_data) {
                    const parsedData = JSON.parse(res.data.data.blog_data);
                    setBlogData(parsedData);
                }
            })
            .catch(err => {
                console.log("Error getting single blog:", err);
            });
    }, [id]);

    // Ensure that blog and blogData exist before rendering
    if (!blog || blogData.length === 0) {
        return <div>Loading...</div>;
    }

    // Generate dynamic SEO metadata
    const pageTitle = blog.blog_heading || "Blog Details";
    const pageDescription = blogData.map(item => item.details).join(" ").slice(0, 160); // Meta description (first 160 chars)
    const pageKeywords = blogData.flatMap(item => item.details.split(" ")).join(", "); // Keywords from details

    return (
        <>
            {/* React Helmet for SEO */}
            <Helmet>
                <title>{pageTitle} | TechJoy Blog</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={pageKeywords} />
                <meta name="author" content="TechJoy" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://techjoy.in/blog/${id}`} />
                <meta property="og:image" content={`${process.env.REACT_APP_API_BLOG_IMAGE}${blogData[0]?.image}`} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={`${process.env.REACT_APP_API_BLOG_IMAGE}${blogData[0]?.image}`} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Existing Page Content */}
            <div className='w-full h-fit bgPurple flex flex-col justify-center items-center md:pt-[152px]'>
                <div className='flex flex-col justify-center items-start md:max-w-[1200px] w-full bg-black md:pl-[100px] pl-[22.37px] pt-[33px] pb-[250px]'>
                    <h1 className='text-white franklinNine md:text-[71.11px] text-[26.67px]'>{blog.blog_heading}</h1>

                    {blogData && blogData.map((item, index) => (
                        <div key={index} className='flex flex-col justify-center items-start w-11/12 h-fit md:gap-[60px] gap-[22.5px] md:mt-[200px] mt-[70px]'>
                            <h2 className='text-white md:text-[43.94px] text-[16.48px] franklinThree w-full'>
                                {index + 1}. {item.heading}
                            </h2>

                            <div className='max-w-[473px] w-1/2 h-fit flex justify-center items-center overflow-hidden md:rounded-[23px] rounded-[8.62px]'>
                                <img className='scale-100' src={`${process.env.REACT_APP_API_BLOG_IMAGE}${item.image}`} alt={item.heading} />
                            </div>

                            <ol className='w-11/12 h-fit text-white list-decimal text-[10.19px] md:text-[27.17px] franklinOne'>
                                {item.details && item.details.split(".").map((detail, i) => (
                                    <li key={i} className='md:pb-8 pb-4'>{detail.trim()}</li>
                                ))}
                            </ol>
                        </div>
                    ))}

                    {/* Sub heading with image and content */}
                    <h3 className='text-white mt-[50px] md:text-[20px] text-[11px]'>
                        You know what? Just by clicking on
                        <a className='text-blue-600 underline' href="https://techjoy.in"> techjoy.in </a>
                        you can completely AUTOMATE your business for <span className='text-green-500'>FREE</span>
                    </h3>
                </div>
            </div>
        </>
    );
};

export default BlogDetail;
