import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const UpdateBlog = () => {

    const [blog, setBlog] = useState(null); // Initialize as null (not an array)
    const [subHeadings, setSubHeadings] = useState([]); // Initialize as empty array (or null if required)
    const { id } = useParams();
    const [blogTitle, setBlogTitle] = useState('')


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_SINGLE_BLOG, { params: { id: id } })
            .then(res => {
                console.log(res.data.data);
                setBlog(res.data.data);
                setBlogTitle(res.data.data.blog_heading)

                if (res.data.data && res.data.data.blog_data) {
                    const parsedData = JSON.parse(res.data.data.blog_data); // Parse JSON correctly
                    setSubHeadings(parsedData);
                }
            })
            .catch(err => {
                console.log("Error getting single blog:", err);
            });
    }, [id]);

    // Ensure that blog and blogData exist before rendering
    if (!blog || subHeadings.length === 0) {
        return <div>Loading...</div>; // Display a loading state
    }

    const handleChange = (index, field, value) => {
        const updatedSubHeadings = subHeadings.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setSubHeadings(updatedSubHeadings);
    };

    const handleAddSubheading = () => {
        setSubHeadings([...subHeadings, { heading: "", image: null, details: "" }]);
    };

    const handleRemoveSubheading = (index) => {
        setSubHeadings(subHeadings.filter((_, i) => i !== index));
    };

    const handleUpdate = () => {
        const formData = new FormData();

        formData.append('id', id);
        formData.append('heading', blogTitle);
        // Append images and replace image field with the name
        const updatedSubHeadings = subHeadings.map((item, index) => {
            if (item.image) {
                formData.append("images", item.image);
                return { ...item, image: item.image.name };
            }
            return item;
        });
        formData.append('blogData', JSON.stringify(updatedSubHeadings));

        axios.put(process.env.REACT_APP_API_UPDATE_BLOG, formData)
            .then(res => {
                console.log(res.data.message);
            }).catch(err => {
                console.log("Error updaing the blog : ", err)
            })

    }


    return (
        <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
            <div className="bg-white p-6 shadow-md rounded-md w-full max-w-2xl">
                <input
                    type="text"
                    placeholder="Blog Title"
                    className="w-full p-2 border rounded-md mb-4"
                    onChange={(e) => setBlogTitle(e.target.value)}
                    value={blogTitle}
                />

                {subHeadings && subHeadings.map((sub, index) => (
                    <div key={index} className="bg-gray-50 p-4 mb-4 rounded-md shadow-sm">
                        <input
                            type="text"
                            placeholder="Sub heading"
                            value={sub.heading}
                            onChange={(e) => handleChange(index, "heading", e.target.value)}
                            className="w-full p-2 border rounded-md mb-2"
                        />
                        <input
                            type="file"
                            onChange={(e) => handleChange(index, "image", e.target.files[0])}
                            className="w-full p-2 border rounded-md mb-2"
                        />
                        <textarea
                            placeholder="Details"
                            value={sub.details}
                            onChange={(e) => handleChange(index, "details", e.target.value)}
                            className="w-full p-2 border rounded-md mb-2"
                        ></textarea>

                        <button
                            onClick={() => handleRemoveSubheading(index)}
                            className="bg-red-500 text-white px-4 py-2 rounded-md"
                        >
                            - Remove subheading
                        </button>
                    </div>
                ))}

                <button
                    onClick={handleAddSubheading}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
                >
                    + Add new subheading
                </button>
            </div>
            <button
                onClick={handleUpdate}
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full mt-4"
            >
                Update blog
            </button>

        </div>
    )
}

export default UpdateBlog
