import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import { UserProvider } from './context/UserContext';
import { ProtectedRoute } from './context/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import { AdminProtectedRoute } from './Admin/Context/AdminProtectedRoute.js';
import Questions from './Admin/Questions/Questions';
import UpdateQuestion from './Admin/Questions/UpdateQuestion.js';
import AnswerQuestions from './pages/AnswerQuestions.js';
import Order from './Admin/Orders/Order.js';
import ClientChat from './Admin/Orders/ClientChat.js';
import ClientAnswers from './Admin/Orders/ClientAnswers.js';
import History from './pages/History.js';
import Answers from './pages/Answers.js';
import Users from './Admin/Users/Users.js';
import { OwnerProtectedRoute } from './Admin/Context/OwnerProtectedRoute.js';
import OrderTable from './Admin/Orders/OrderTable.js';
import Register from './pages/Register.js';
import TeamChat from './Admin/Orders/TeamChat.js';
import AllChat from './Admin/Orders/AllChat.js';
import LeadSqueeze from './pages/Funnels/LeadSqueeze.js';
import LoadingLead from './pages/Funnels/LoadingLead.js';
import Reward from './pages/Funnels/Reward.js';
import Leads from './Admin/Funnel/Leads.js';
import FunnelOrder from './Admin/Funnel/FunnelOrder.js';
import Offer from './pages/Funnels/Offer.js';
import CheckoutPage from './Component/CheckoutPage.js';
import Navbar from './Component/Navbar.js';
import { Toaster } from 'sonner';
import ScrollToTop from '../src/Component/ScrollToTop.js'
import CompletedOrders from './Admin/Orders/CompletedOrders.js';
import CancelledOrders from './Admin/Orders/CancelledOrders.js';
import UpdatePackage from './Admin/Package/UpdatePackage.js';
import Blog from './pages/Blog.js';
import BlogDetail from './pages/BlogDetail.js';
import AddBlog from './Admin/Blog/AddBlog.js';
import UpdateBlog from './Admin/Blog/UpdateBlog.js';
import TermsAndConditions from './pages/TermsAndConditions.js';


function App() {

  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 5000,
          style: {
            borderRadius: '8px',
            background: '#2D335B', // Darker background matching the theme
            color: '#EDEDF7', // Light text for contrast
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
            border: '1px solid #5C67F2', // Highlight border matching button color
          },
        }}
      />
      <UserProvider>
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/' element={<LeadSqueeze />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Register />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/blog/:id' element={<BlogDetail />} />
            <Route path='/blog/update/:id' element={<UpdateBlog />} />
            <Route path='/loading/:id' element={<LoadingLead />} />
            <Route path='/rewards' element={<Reward />} />
            <Route path='/full-stack-luxury' element={<Offer />} />
            <Route path='/full-stack-luxury/checkout' element={<CheckoutPage />} />
            <Route path='/blog/add' element={<AdminProtectedRoute>
              <AddBlog />
            </AdminProtectedRoute>} />

            <Route path='/dashboard' element={<ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>} />
            <Route path='/dashboard/history' element={<ProtectedRoute>
              <History />
            </ProtectedRoute>} />
            <Route path='/answers' element={<ProtectedRoute>
              <AnswerQuestions />
            </ProtectedRoute>} />
            {/* admin code  */}
            <Route path='/admin/questions' element={<OwnerProtectedRoute>
              <Questions />
            </OwnerProtectedRoute>} />
            <Route path='/admin/question/update/:id' element={<OwnerProtectedRoute>
              <UpdateQuestion />
            </OwnerProtectedRoute>} />
            <Route path='/admin/leads' element={<OwnerProtectedRoute>
              <Leads />
            </OwnerProtectedRoute>} />
            <Route path='/admin/funnel/orders' element={<OwnerProtectedRoute>
              <FunnelOrder />
            </OwnerProtectedRoute>} />
            <Route path='/admin/orders' element={<AdminProtectedRoute>
              <Order />
            </AdminProtectedRoute>} />
            <Route path='/admin/orders/chat/:id' element={<AdminProtectedRoute>
              <ClientChat />
            </AdminProtectedRoute>} />
            <Route path='/admin/orders/teamchat/:id' element={<AdminProtectedRoute>
              <TeamChat />
            </AdminProtectedRoute>} />
            <Route path='/admin/orders/allchat/:id' element={<AdminProtectedRoute>
              <AllChat />
            </AdminProtectedRoute>} />
            <Route path='/admin/orders/answer' element={<AdminProtectedRoute>
              <Answers />
            </AdminProtectedRoute>} />
            {/* owner routes  */}
            <Route path='/admin/users' element={<OwnerProtectedRoute>
              <Users />
            </OwnerProtectedRoute>} />
            <Route path='/admin/orderdata' element={<OwnerProtectedRoute>
              <OrderTable />
            </OwnerProtectedRoute>} />
            <Route path='/admin/completed/orderdata' element={<OwnerProtectedRoute>
              <CompletedOrders />
            </OwnerProtectedRoute>} />
            <Route path='/admin/cancelled/orderdata' element={<OwnerProtectedRoute>
              <CancelledOrders />
            </OwnerProtectedRoute>} />
            <Route path='/admin/packagedata' element={<OwnerProtectedRoute>
              <UpdatePackage />
            </OwnerProtectedRoute>} />
            <Route path='/admin/blog/add' element={<OwnerProtectedRoute>
              <AddBlog />
            </OwnerProtectedRoute>} />

          </Routes>
        </Router>
        {/* <Payment /> */}
      </UserProvider>
    </div>
  );
}

export default App;
