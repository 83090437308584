import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Blog = () => {
    const [blogData, setBlogData] = useState([]);
    const navigate = useNavigate();

    const handleBlogDetail = (id) => {
        navigate(`/blog/${id}`)
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_BLOG)
            .then(res => {
                console.log(res.data);
                setBlogData(res.data.data);
            }).catch(err => {
                console.log("Error getting the blog data : ", err)
            })
    }, [])
    return (
        <>
            <Helmet>
                {/* 🎯 High-Impact Title for Click-Throughs */}
                <title>TechJoy Blog | Business Automation Tips, Tools & Strategies 🚀</title>

                {/* 📝 Click-Worthy Meta Description */}
                <meta
                    name="description"
                    content="Stay ahead in business automation with TechJoy’s blog! Learn how AI, SaaS, and automation tools can scale your business while reducing workload & boosting revenue."
                />

                {/* 🔥 Keyword-Rich Meta Tags for High Search Rankings */}
                <meta
                    name="keywords"
                    content="TechJoy blog, business automation tips, AI automation, SaaS business solutions, how to automate a business, scale business without employees, productivity automation, passive income automation, AI business tools, automate work, grow business fast, full stack automation, CRM automation, marketing automation, finance automation, TechJoy articles, business automation guides"
                />

                {/* 🚀 Open Graph for Facebook, LinkedIn, WhatsApp */}
                <meta property="og:title" content="TechJoy Blog | The Best Business Automation Insights 🚀" />
                <meta property="og:description" content="Explore expert articles on automating your business with AI, SaaS, and smart strategies. Learn how to work less & earn more!" />
                <meta property="og:image" content="https://techjoy.in/path-to-blog-thumbnail.jpg" />
                <meta property="og:url" content="https://techjoy.in/blogs" />
                <meta property="og:type" content="website" />

                {/* 🐦 Twitter SEO Optimization */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="TechJoy Blog | Business Automation Made Simple 🚀" />
                <meta name="twitter:description" content="Discover how to scale your business with automation & AI. Get expert tips on reducing workload & increasing profit!" />
                <meta name="twitter:image" content="https://techjoy.in/path-to-blog-thumbnail.jpg" />

                {/* 📌 Canonical URL to Avoid Duplicate Content Issues */}
                <link rel="canonical" href="https://techjoy.in/blogs" />

                {/* 🔎 Local SEO (Google Business Listings) */}
                <meta name="geo.region" content="IN" />
                <meta name="geo.placename" content="India" />
                <meta name="geo.position" content="19.0760;72.8777" />
                <meta name="ICBM" content="19.0760, 72.8777" />

                {/* 📈 Google Structured Data (Schema Markup) for Rich Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Blog",
                        "name": "TechJoy Blog – Business Automation Insights",
                        "description": "Stay updated with the latest trends in business automation, AI tools, SaaS solutions & strategies to scale your business.",
                        "url": "https://techjoy.in/blogs",
                        "publisher": {
                            "@type": "Organization",
                            "name": "TechJoy",
                            "url": "https://techjoy.in/"
                        },
                        "blogPosts": {
                            "@type": "BlogPosting",
                            "headline": "How to Automate Your Business & Work Less",
                            "datePublished": "2024-02-13",
                            "author": {
                                "@type": "Person",
                                "name": "TechJoy Experts"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <div className='w-full h-fit flex flex-col justify-center items-center pt-[100px]'>
                {/* blog container  */}
                <div className='flex flex-row justify-center items-center gap-6 flex-wrap'>

                    {
                        blogData && blogData.slice().reverse().map((item, index) => (
                            <div onClick={() => handleBlogDetail(item.blog_id)} className='max-w-[535px] w-11/12 min-h-[286px] h-fit bg-black flex flex-col justify-between items-start pl-[35px] pr-[5px] p-[20px] cursor-pointer'>

                                <h2 className='franklinNine md:text-[24.29px] text-[18.29px] text-white w-full mt-[20px]'>{item.blog_heading}</h2>

                                <div>
                                    <p className='text-white text-[9.28px] mb-2'>{item.date}</p>
                                    <button onClick={() => handleBlogDetail(item.blog_id)} className='w-[117px] h-[33px] flex justify-center items-center bgPurple text-black text-[12.92px]'>Read More</button>
                                </div>

                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    )
}

export default Blog
