import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Temp from './Temp';
import { Link } from 'react-router-dom'
import Answers from './Answers';
import { CheckCircle, XCircle, ChevronRight, CreditCard } from 'lucide-react';

const Dashboard = () => {
    const { user, logout } = useContext(UserContext);
    const [isOrder, SetIsOrder] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [isApproval, setIsApproval] = useState(false);
    const [designApproved, setDesignApproved] = useState(false);
    const [designLink, setDesignLink] = useState('');
    const [answers, setAnswers] = useState([]);
    const [isclicked, setIsClicked] = useState(false);


    //stages of meeting 
    const items = ["Meeting", "Home page design approval", "Designing", "Development", "Demo + Coaching", "Website live", "Document Received", "Done"];
    const [status, setStatus] = useState("");



    const navigate = useNavigate();

    const cancelOrder = () => {
        alert('Are you sure , Do you want to cancel this order')
        axios.put(process.env.REACT_APP_API_CANCEL_ORDER, { orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                alert(res.data);
                window.location.reload();
            }).catch(err => {
                console.log("Error cancelling the order : ", err)
            })
    }



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_CHECK_PURCHASE}?userId=${user.userId}}`)
            .then(res => {
                console.log(res.data.data[0].status)
                setStatus(res.data.data[0].status);
                console.log(res.data.message);
                SetIsOrder(res.data.order)
                //order id 
                setOrderId(res.data.data[0].order_id);
                //check for design approval
                setIsApproval(res.data.data[0].desing_approval_live);
                //check if the design is approveed 
                setDesignApproved(res.data.data[0].desing_approved);
                // set the design link
                setDesignLink(res.data.data[0].design_link);
                //setanswers
                const answerInfo = res.data.data[0].answers;
                setAnswers(JSON.parse(answerInfo));

                //checking if step one is completed or not 
                if (res.data.data[0].answers.length === 0) {
                    setStepTwo(false); // Corrected function naming convention (camelCase)
                } else {
                    setStepTwo(true);
                }
            }).catch(err => {
                console.log(err)
            })


    }, [user])

    const handleStepOne = () => {
        navigate('/answers');
    }


    const approveDesign = () => {
        axios.put(process.env.REACT_APP_API_APPROVE_DESIGN, { orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("error approving design : ", err)
            })
    }

    const disApproveDesign = () => {
        axios.put(process.env.REACT_APP_API_DISAPPROVE_DESIGN, { orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("error approving design : ", err)
            })
    }

    return (

        <div className="flex flex-col bg-gradient-to-br from-purple-50 to-purple-100 p-4">
            <div className="w-full max-w-4xl mx-auto bg-white shadow-2xl rounded-3xl overflow-hidden flex flex-col">

                <div className="bg-gradient-to-r from-[#703BE7] to-purple-600 p-6 text-white">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold flex items-center">
                            <CreditCard className="mr-3" size={32} />
                            Order Progress
                        </h1>
                        <Link
                            onClick={() => logout()}
                            className="flex items-center text-white hover:text-purple-200 transition-colors"
                        >
                            Logout
                        </Link>
                        <Link
                            to='/dashboard/history'
                            className="flex items-center text-white hover:text-purple-200 transition-colors"
                        >
                            See History <ChevronRight className="ml-2" size={20} />
                        </Link>
                    </div>
                </div>
                {/* Main Content - Flexible Vertical Layout */}
                <div className="flex flex-col space-y-6 p-6 overflow-y-auto">
                    {isOrder ? (
                        <>
                            {/* Header Section */}

                            {/* Order Initiation */}
                            <div className="bg-purple-50 rounded-2xl p-6 shadow-md">
                                <h2 className="text-xl font-bold text-purple-800 mb-4">
                                    Start Your Project
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    Thanks for ordering! Complete these steps to begin.
                                </p>
                                {answers && answers.length > 0 ? (
                                    <div className="mb-4">
                                        <p className="text-lg text-purple-700">Step 1 Completed</p>
                                        <button
                                            disabled
                                            className="mt-2 w-full px-4 py-3 bg-gray-500 text-white rounded-lg hover:bg-gray-700 transition-colors flex items-center justify-center"
                                        >
                                            Step 1 completed
                                        </button>
                                    </div>
                                ) : (
                                    <div className="mb-4">
                                        <p className="text-lg text-purple-700">Step 1: Answer Initial Questions</p>
                                        <button
                                            onClick={() => handleStepOne()}
                                            className="mt-2 w-full px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
                                        >
                                            Complete Step 1
                                        </button>
                                    </div>
                                )}
                            </div>
                            {/* Design Section */}
                            <div className="bg-purple-50 rounded-2xl overflow-hidden shadow-md">
                                {designLink && designLink.length > 0 ? (
                                    <div className="w-full aspect-video">
                                        <div className="p-6 text-center text-purple-600">
                                            Live Design
                                        </div>
                                        <iframe
                                            src={designLink}
                                            allowFullScreen
                                            className="w-full h-full transform transition-transform duration-300"
                                        ></iframe>
                                        <p className='p-2 text-gray-600'>1. Use Ctrl + scrollwheel to Zoom in & out</p>
                                        <p className='p-2 text-gray-600'>2. Right click to drag through the design</p>
                                    </div>
                                ) : (
                                    <div className="p-6 text-center text-purple-600">
                                        We will see a design here shortly, please wait
                                    </div>
                                )}
                            </div>

                            {/* Answer Toggle Section */}
                            <div className="bg-purple-50 rounded-2xl p-6 shadow-md">
                                {isclicked ? (
                                    <div className="space-y-4">
                                        <button
                                            onClick={() => setIsClicked(false)}
                                            className="w-full px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
                                        >
                                            <XCircle className="mr-2" size={20} /> Hide Answers
                                        </button>
                                        <Answers answers={answers} />
                                    </div>
                                ) : (
                                    <button
                                        onClick={() => setIsClicked(true)}
                                        className="w-full px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
                                    >
                                        <CheckCircle className="mr-2" size={20} /> Show Answers
                                    </button>
                                )}
                            </div>


                            {/* Detailed Progress Section */}
                            {stepTwo && (
                                <div className="bg-[#703BE7] rounded-2xl p-6 text-white space-y-6">
                                    {/* Design Approval */}
                                    <div className="space-y-4">
                                        <h3 className="text-xl font-semibold text-center">Design Review</h3>
                                        {isApproval ? (
                                            designApproved ? (
                                                <div className="flex items-center justify-center space-x-2 bg-green-500 rounded-lg p-3">
                                                    <CheckCircle size={24} />
                                                    <p>Design Approved</p>
                                                </div>
                                            ) : (
                                                <div className="flex space-x-4 justify-center">
                                                    <button
                                                        onClick={() => approveDesign(orderId)}
                                                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors flex items-center"
                                                    >
                                                        <CheckCircle className="mr-2" size={20} /> Approve
                                                    </button>
                                                    <button
                                                        onClick={() => disApproveDesign(orderId)}
                                                        className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors flex items-center"
                                                    >
                                                        <XCircle className="mr-2" size={20} /> Dis-approve
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            <button
                                                disabled
                                                className="w-full px-4 py-2 bg-gray-400 text-white rounded-lg cursor-not-allowed"
                                            >
                                                Approve Design
                                            </button>
                                        )}
                                    </div>

                                    {/* User and Progress */}
                                    <div className="text-center space-y-4">
                                        <h2 className="text-lg font-medium">{user.email}</h2>

                                        <div className="bg-purple-700 rounded-lg p-4">
                                            <p className="text-xl mb-4">Progress Tracker</p>
                                            <div className="flex flex-wrap justify-between">
                                                {items && items.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex flex-col items-center w-[100px] space-y-2"
                                                    >
                                                        <div
                                                            className={`w-10 h-10 rounded-full flex items-center justify-center ${index < status ? "bg-green-500" : "bg-red-500"
                                                                }`}
                                                        >
                                                            {index < status ? <CheckCircle size={20} /> : <XCircle size={20} />}
                                                        </div>
                                                        <p className="text-center text-sm">{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Cancel Order */}
                                    <div className="text-center">
                                        <button
                                            onClick={() => cancelOrder()}
                                            className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors flex items-center justify-center mx-auto"
                                        >
                                            <XCircle className="mr-2" size={20} /> Cancel Order
                                        </button>
                                    </div>

                                    {/* Chat Component */}
                                    <div className="w-full mt-6">
                                        <Temp room={orderId} />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="bg-gray-100 rounded-2xl p-6 text-center">
                            <h1 className="text-xl font-semibold text-purple-800">
                                There are no purchases
                                {/* <Payment password="" email={user.email} packageId={2} /> */}
                                {/* <button onClick={handlePayment}>Pay 596</button> */}
                                {/* <RazorpayPayment password="" email={user.email} phone={user.phone} /> */}
                                <div>
                                    <button
                                        onClick={() => navigate('/full-stack-luxury')}
                                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
                                    >
                                        Click to AUTOMATE your BUSINESS now
                                    </button>
                                </div>
                                {/* <Offer /> */}
                            </h1>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Dashboard
