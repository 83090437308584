import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_REGISTER, { email: email, password: password, phone: phone })
            .then(res => {
                toast.success(res.data.message);
                navigate('/login');
            }).catch(err => {
                console.log("Error registering user : ", err);
                toast.error("Error Registering user");
            })

    }
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
            <form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded-lg px-8 py-6 w-80 border border-gray-200"
            >
                <h2 className="text-2xl font-semibold text-purple-700 text-center mb-4">
                    Register
                </h2>
                <input
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <input
                    placeholder="Phone"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <button
                    type="submit"
                    className="w-full bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 rounded-md transition"
                >
                    Register
                </button>
            </form>
        </div>

    )
}

export default Register
